import {
    GET_PRAYERS,
    POST_SALAT
} from '../actions/types';
const initialState = {
    tomorrowApiPrayerTime: null,
    prayerTimesLoading: true,
    tomorrowIqamaTime: null,
    acceptPortrait: true,
    rotationFeature: { rotationDegree: null, isPortrait: false },
    hijriPreference: "0",
    focusModeTimes: null,
    apiPrayerTime: null,
    prayerTimeYear: [],
    visualControl: null,
    bigFontSize: false,
    announcement: null,
    fontWeight: 400,
    highlighted: null,
    prayerTime: null,
    tvLangsOne: "ar",
    tvLangsTwo: "en",
    focusMode: false,
    screenName: null,
    tvSettings: null,
    iqamaTime: null,
    isOnline: true,
    version: null,
    payload: null,
    isIqama: true,
    entity: null,
    qrCode: null,
    isCsv: null,
    qibla: null,
    logo: null,
    date: null,
    posts: [],
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    switch ( type ) {

        case GET_PRAYERS:
            return {
                ...state,
                // darkMode: state.darkMode === null ? ( action.payload.darkModeStting === 'true' ) : state.darkMode,
                tomorrowApiPrayerTime: payload.tomorrowApiPrayerTime,
                hijriPreference: payload.hijriPreference ?? "0",
                tomorrowIqamaTime: payload.tomorrowIqamaTime,
                prayerTimeYear: payload.prayerTimeYear,
                tvLangsOne: payload.tvLangsOne ?? "ar",
                tvLangsTwo: payload.tvLangsTwo ?? "en",
                backgroundImage: payload.backgroundImage,
                // darkModeStting: payload.darkModeStting,
                adhanAudioMode: payload.adhanAudioMode,
                focusModeTimes: payload.focusModeTimes,
                apiPrayerTime: payload.apiPrayerTime,
                visualControl: payload.visualControl,
                announcement: payload.announcement,
                highlighted: payload.highlighted,
                bigFontSize: payload.bigFontSize,
                prayerTime: payload.prayerTime,
                screenName: payload.screenName,
                tvSettings: payload.tvSettings,
                forceWhite: payload.forceWhite,
                iqamaTime: payload.iqamaTime,
                isOnline: payload.isOnline,
                prayerTimesLoading: false,
                version: payload.version,
                posts: payload.somePosts,
                isIqama: payload.isIqama,
                entity: payload.entity,
                isCsv: payload.isCsv,
                qibla: payload.qibla,
                date: payload.date,
                logo: payload.logo,
            };
        case POST_SALAT:
            return {
                ...state,
                tomorrowApiPrayerTime: payload.tomorrowApiPrayerTime,
                hijriPreference: payload.hijriPreference ?? "0",
                tomorrowIqamaTime: payload.tomorrowIqamaTime,
                backgroundImage: payload.backgroundImage,
                rotationFeature: payload.rotationFeature,
                prayerTimeYear: payload.prayerTimeYear,
                tvLangsOne: payload.tvLangsOne ?? "ar",
                tvLangsTwo: payload.tvLangsTwo ?? "en",
                adhanAudioMode: payload.adhanAudioMode,
                focusModeTimes: payload.focusModeTimes,
                apiPrayerTime: payload.apiPrayerTime,
                visualControl: payload.visualControl,
                announcement: payload.announcement,
                acceptPortrait: payload.portraitOn,
                highlighted: payload.highlighted,
                bigFontSize: payload.bigFontSize,
                fontWeight: payload.fontWeight,
                screenName: payload.screenName,
                prayerTime: payload.prayerTime,
                tvSettings: payload.tvSettings,
                forceWhite: payload.forceWhite,
                iqamaTime: payload.iqamaTime,
                isOnline: payload.isOnline,
                prayerTimesLoading: false,
                version: payload.version,
                posts: payload.somePosts,
                isIqama: payload.isIqama,
                qrCode: payload.qrCode,
                entity: payload.entity,
                isCsv: payload.isCsv,
                qibla: payload.qibla,
                date: payload.date,
                logo: payload.logo,
                payload: payload,

            };
        default:
            return state;
    }

}